<template>
<div class="forgot">
  <div class="background">
    <div class="container">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter has-text-centered">
          <div class="title-login">
            <p><b>{{ $t('RECOVERY_PASSWORD.TITLE')}}</b></p>
          </div>
        </div>
      </div>
      <div class="columns" v-if="isSuccess">
        <div class="column is-half is-offset-one-quarter">
          <MessageBox type="success" :title="$t('RECOVERY_PASSWORD.MESSAGE_SUCCESS')" subtitle=""/>
        </div>
      </div>
      <div class="columns" v-if="isError">
        <div class="column is-half is-offset-one-quarter">
          <MessageBox type="error" :title="$t('RECOVERY_PASSWORD.MESSAGE_ERROR')" subtitle=""/>
        </div>
      </div>
      <div class="columns" v-if="!isSuccess && !isError">
        <div class="column is-half is-offset-one-quarter">
          <form @submit.prevent="validateBeforeSubmit">
            <div class="login-auth">
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <b-field :type="{'is-danger': errors.has('password')}" >
                    <b-input v-validate="{ required: true, regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.*){8,}$/, min: 8 }" name="password" v-model.trim="recoveryForm.password" :placeholder="$t('REGISTER.PASSWORD')" type="password" ref="password" custom-class="custom-input-i" icon-pack="fal" icon="lock-alt"></b-input>
                  </b-field>
                  <span v-if="errors.has('password')" class="help is-danger">{{ $t('REGISTER.ERROR_PASSWORD', { field: $t('REGISTER.PASSWORD')}) }}</span>
                </div>
              </div>
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <b-field :type="{'is-danger': errors.has('repeat password')}">
                    <b-input v-validate="'required|confirmed:password'" name="repeat password" v-model.trim="recoveryForm.confirmPassword" :placeholder="$t('REGISTER.CONFIRM_PASSWORD')" type="password" custom-class="custom-input-i" icon-pack="fal" icon="lock-alt"></b-input>
                  </b-field>
                  <span v-if="errors.has('repeat password')" class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', { field: $t('REGISTER.CONFIRM_PASSWORD')}) }}</span>
                </div>
              </div>
              <div class="columns">
                <div class="column is-8 is-offset-2 column-button">
                  <button class="button btn-login">
                    <span><b>{{ $t('STIMULUS.send')}}</b></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MessageBox from "@/components/MessageBox.vue";

export default {
  name: "recovery",
  components: {
    MessageBox
  },
  data() {
    return {
      isSend: false,
      isSuccess: false,
      isError: false,
      recoveryForm: {
        password: "",
        confirmPassword: ""
      }
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const email = this.$route.params.email;
          const hash_recuperacion = this.$route.params.hash;
          const password = this.recoveryForm.password;
          this.$store.commit("setShowSpinner", true);
          this.$store.dispatch("changePassword", { email, password, hash_recuperacion }).then(user => {
            this.isSuccess = true;
            this.$store.commit("setShowSpinner", false);
          }).catch(error => {
            this.isError = true;
            this.$store.commit("setShowSpinner", false);
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.background {
  background: url("../assets/img/Fondo-registro.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  width: 100%;
  height: calc(100% - 120px);
}

.title-login {
  color: white;
  font-size: 2rem;
  padding-top: 3rem;
}

.login-auth {
  background-color: white;
  border-radius: .1rem;
  padding: 3.5rem 0;
  margin-bottom: 2rem;
}

.column-button {
  padding-top: 2.5rem;
  margin-bottom: -.5rem;
}

.quadre-info {
  padding: .5rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid $color-text;
  font-size: .95rem;
  color: $color-text;
}

.quadre-info .paragraf {
  padding-top: 1rem;
  font-size: .95rem;
}

.link-reset-pwd {
  color: $color-text;
  font-size: .8rem;
}

.btn-login {
  background-color: $primary;
  color: white;
  height: 60px;
  width: 100%;
  border-radius: 10px;
}

.btn-login:hover {
  color: white;
}
</style>
